import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import './ChatMessage.css';

const ChatMessage = ({ message, from }) => {
  const preprocessMessage = (text) => {
    if (!text || typeof text !== 'string') return text;

    // Remove citation markers
    let processed = text.replace(/【[^】]*】/g, '');

    // Replace display math delimiters \[ and \] with $$...$$
    processed = processed
      .replace(/\\\[/g, '$$')   // Replace \[ with $$
      .replace(/\\\]/g, '$$')   // Replace \] with $$

      // Replace inline math delimiters \( and \) with $...$
      .replace(/\\\(/g, '$')    // Replace \( with $
      .replace(/\\\)/g, '$');   // Replace \) with $

    return processed;
  };

  const remarkMathOptions = {
    singleDollarTextMath: true,  // Enable single dollar for inline math
  };

  return (
    <div className={`chat-message ${from}`}>
      <div className="message">
        <ReactMarkdown
          className="markdown-content"
          children={preprocessMessage(message)}
          remarkPlugins={[[remarkMath, remarkMathOptions]]} // Enables math processing
          rehypePlugins={[rehypeRaw, rehypeKatex]} // Enables HTML and KaTeX support
          components={{
            p: ({ children }) => <>{children}</>, // Remove extra <p> tags
          }}
        />
      </div>
    </div>
  );
};

export default ChatMessage;
