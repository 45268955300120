import React, { useRef, useState, useEffect } from 'react';
import './PresetQuestions.css';

const PresetQuestions = ({ onSelectQuestion }) => {
    const scrollContainerRef = useRef(null);
    const [mousePosition, setMousePosition] = useState(null);
    const [randomizedQuestions, setRandomizedQuestions] = useState([]);
    
    const allQuestions = [
        "What camera would you recommend for low-light imaging in an industrial environment?",
        "Can you tell me more about your SWIR camera options?",
        "I need a camera for crystal orientation mapping. Which product would be suitable?",
        "How does the HAWKeye sCMOS Camera compare to your Nightfall-HR Camera in terms of resolution and noise?",
        "Do you offer cameras that are suitable for semiconductor inspection in the SWIR range?",
        "Can your X-ray detectors be used for phase contrast imaging?",
        "I work in airborne surveillance and need a SWIR camera. Which model should I consider?",
        "Do you have any recommendations for a camera suited to single-molecule detection?",
        "I need a solution for laser-induced breakdown spectroscopy. Do you have cameras that fit this need?",
        "What kind of image analysis tools does PSViewer provide?",
        "Can I integrate your cameras into a LabVIEW-based system?",
        "Does PSViewer support camera control for your Laue diffraction systems?",
        "Do you offer SDKs for custom software integration?",
        "Is PSViewer compatible with Windows 11?",
        "Can PSEL design a custom camera solution tailored to specific needs?",
        "I have a unique imaging requirement that involves X-ray and neutron detection. Can you assist with a custom system?",
        "Are you able to provide OEM solutions with FPGA design and custom camera hardware?",
        "If none of your standard products fit my needs, can you develop a bespoke product?",
        "What is the process for discussing a potential custom camera build?",
        "Can I get a quote for the Nightfall-HR Camera?",
        "Can you provide a quotation for your 16MP X-ray sCMOS Detector?",
        "How do I request a formal quote for your SWIR camera range?",
        "Are there upcoming products for high-speed imaging in low light?",
        "Can I speak to someone about future developments in X-ray cameras?",
        "I’m having trouble with PSViewer software. Who should I contact for support?",
        "Is there support available if I encounter issues with camera integration?",
        "Can I get help with connecting a PSEL camera to my existing software?",
        "I need assistance with calibration on my SWIR camera. Who can I reach out to?",
        "What is the maximum frame rate of the Nightfall-HR Camera?",
        "Do your SWIR cameras come with thermoelectric cooling options?",
        "What options do you have for high-speed imaging in the visible spectrum?",
        "Can I control your cameras remotely over a network?",
        "What is the spectral range of the Extended SWIR XT+ camera?",
        "How does the cooling system work on your cooled sCMOS cameras?",
        "Can the PSViewer software save images in a specific file format, like TIFF or JPEG?",
        "Do you offer any training on using your Laue diffraction systems?",
        "Are your cameras compatible with third-party software like LabVIEW?",
        "Do you have any guidance for integrating your cameras into automated inspection systems?",
        "What data interfaces do your cameras support, such as USB, Ethernet, or Camera Link?",
        "Can your cameras handle continuous operation over long periods?",
        "What resolutions are available for your cooled sCMOS models?",
        "Is the Laue diffraction system customizable to fit different crystal sizes?",
        "Can the grain map configuration in the Laue system be automated?",
        "How do I know which SWIR camera is right for my application?",
        "Are there any government regulations or certifications for using your X-ray products?",
        "Does PSViewer support real-time data analysis during image capture?",
    ];

    useEffect(() => {
        const shuffled = [...allQuestions].sort(() => 0.5 - Math.random());
        setRandomizedQuestions(shuffled);
    }, []);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (!container) return;

        const handleMouseMove = (e) => {
            const rect = container.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            setMousePosition(mouseX);
        };

        const handleScroll = () => {
            if (mousePosition === null || !container) return;
            const containerWidth = container.offsetWidth;
            const scrollWidth = container.scrollWidth;
            
            // Define scroll zones (first and last 25% of container)
            const scrollZoneSize = containerWidth * 0.25;
            
            if (mousePosition < scrollZoneSize) {
                // Left scroll zone
                const scrollStrength = 1 - (mousePosition / scrollZoneSize);
                container.scrollLeft -= scrollStrength * 8; // Reduced from 20 to 8
            } else if (mousePosition > containerWidth - scrollZoneSize) {
                // Right scroll zone
                const scrollStrength = (mousePosition - (containerWidth - scrollZoneSize)) / scrollZoneSize;
                container.scrollLeft += scrollStrength * 8; // Reduced from 20 to 8
            }
        };

        container.addEventListener('mousemove', handleMouseMove);
        
        let animationFrameId;
        const animate = () => {
            handleScroll();
            animationFrameId = requestAnimationFrame(animate);
        };
        animate();

        const handleMouseLeave = () => {
            setMousePosition(null);
        };

        container.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseleave', handleMouseLeave);
            cancelAnimationFrame(animationFrameId);
        };
    }, [mousePosition]);

    return (
        <div className="preset-questions-container">
            <div 
                ref={scrollContainerRef}
                className="preset-questions-scroll"
            >
                {randomizedQuestions.map((question, index) => (
                    <button
                        key={index}
                        onClick={() => onSelectQuestion(question)}
                        className="preset-question-button"
                    >
                        {question}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PresetQuestions;