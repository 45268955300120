import { MathJaxContext } from 'better-react-mathjax';
import Chatbox from './components/Chatbox';
import './App.css';

function App() {
  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ['$', '$'],
        ['\\(', '\\)']  // Added support for \( \) notation
      ],
      displayMath: [
        ['$$', '$$'],
        ['\\[', '\\]'],
        ['[', ']']      // Added support for [ ] notation
      ]
    },
    startup: {
      typeset: false
    },
    options: {
      skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre', 'code'],
      processHtmlClass: 'math'
    },
    svg: {
      fontCache: 'global'
    },
    chtml: {
      displayAlign: 'left',
      displayIndent: '0'
    }
  };

  return (
    <div className="App">
      <MathJaxContext config={config}>
        <div className="chat-container">
          <Chatbox />
        </div>
      </MathJaxContext>
    </div>
  );
}

export default App;