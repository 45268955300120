import { useState, useEffect } from 'react';

const LoadingIndicator = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => {
        if (prevDots.length >= 3) return '';
        return prevDots + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-indicator">
      <style>
        {`
          .loading-indicator {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px;
          }

          .spinner {
            animation: spin 1s linear infinite;
            width: 16px;
            height: 16px;
            border: 2px solid #ccc;
            border-top-color: #666;
            border-radius: 50%;
          }

          .thinking-text {
            color: #666;
            font-size: 14px;
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div className="spinner" />
      <span className="thinking-text">Thinking{dots}</span>
    </div>
  );
};

export default LoadingIndicator;