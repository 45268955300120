import React, { useState, useRef, useEffect } from 'react';
import './Chatbox.css';
import ChatMessage from './ChatMessage';
import PresetQuestions from './PresetQuestions';
import LoadingIndicator from './LoadingIndicator';
import config from '../config';

const Chatbox = () => {
    const [message, setMessage] = useState('');
    const [conversation, setConversation] = useState([]);
    const [thread_id, setThread_id] = useState('new');
    const [chatEnabled, setChatEnabled] = useState(true);
    const [isThinking, setIsThinking] = useState(false);
    const endOfMessagesRef = useRef(null);
    const backendIp = config.backendIp;

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversation]);

    const sendMessage = async (messageToSend = message) => {
        if (!messageToSend.trim()) return;
        
        setChatEnabled(false);
        setMessage('');
    
        setConversation(prev => [...prev, { msg: messageToSend, from: 'user' }]);
        
        try {
            setIsThinking(true);
            setConversation(prev => [...prev, { msg: 'Thinking...', from: 'bot', isThinking: true }]);
    
            const eventSource = new EventSource(
                `${backendIp}/ask?message=${encodeURIComponent(messageToSend)}&thread_id=${encodeURIComponent(thread_id)}`
            );
            
            let isFirstMessage = true;
            
            eventSource.onmessage = (event) => {
                if (event.data === '[DONE]') {
                    eventSource.close();
                    setIsThinking(false);
                    setChatEnabled(true);
                    return;
                }
    
                try {
                    const data = JSON.parse(event.data);
                    
                    if (data.thread_id) {
                        setThread_id(data.thread_id);
                    } else if (data.content) {
                        setConversation(prev => {
                            const newConversation = [...prev];
                            if (isFirstMessage) {
                                newConversation[newConversation.length - 1] = {
                                    msg: data.content,
                                    from: 'bot',
                                    isThinking: false
                                };
                                isFirstMessage = false;
                            } else {
                                const lastMessage = newConversation[newConversation.length - 1];
                                newConversation[newConversation.length - 1] = {
                                    msg: lastMessage.msg + data.content,
                                    from: 'bot',
                                    isThinking: false
                                };
                            }
                            return newConversation;
                        });
                    }
                } catch (error) {
                    console.error('Error parsing event data:', error);
                }
            };
    
            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
                setIsThinking(false);
                setConversation(prev => {
                    const newConversation = [...prev];
                    newConversation[newConversation.length - 1] = {
                        msg: "Failed to get response. Please try again.",
                        from: 'bot',
                        isThinking: false
                    };
                    return newConversation;
                });
                setChatEnabled(true);
            };
    
        } catch (error) {
            console.error("Error when sending message: ", error);
            setIsThinking(false);
            setConversation(prev => {
                const newConversation = [...prev];
                newConversation[newConversation.length - 1] = {
                    msg: "Failed to get response. Please try again.",
                    from: 'bot',
                    isThinking: false
                };
                return newConversation;
            });
            setChatEnabled(true);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        sendMessage();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };

    const clearChat = (e) => {
        e.preventDefault();
        setConversation([]);
        setThread_id('new');
        localStorage.removeItem('thread_id');
    };

    const handlePresetQuestion = (question) => {
        sendMessage(question);
    };

    return (
        <div className="chat-container">  
            <div className="chat-header"> 
                <img className="logo" src='main-logo.png' alt="PSEL Logo" />
                <button type="reset" onClick={clearChat}>Clear Chat</button>
            </div>
            <div className="chat-body">
                {conversation.map((entry, index) => (
                    <div key={index} className={`chat-message ${entry.from} ${entry.isThinking ? 'thinking' : ''}`}>
                        {entry.isThinking ? (
                            <div className="message">
                                <LoadingIndicator />
                            </div>
                        ) : (
                            <ChatMessage message={entry.msg} from={entry.from} />
                        )}
                    </div>
                ))}
                <div ref={endOfMessagesRef} />
            </div>
            <form onSubmit={handleFormSubmit}>
                {conversation.length === 0 && (
                    <div className="px-4">
                        <p className="text-gray-700 mb-3">Select a question or type your own message:</p>
                        <PresetQuestions onSelectQuestion={handlePresetQuestion} />
                    </div>
                )}
                <p className='disclaimer'><em>Note: PSEL AI is experimental and can give unverified information</em></p>
                <div className='chat-input'>
                    <textarea 
                        disabled={!chatEnabled} 
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                        placeholder="Type a message..." 
                        onKeyDown={handleKeyDown}
                    />
                    <button disabled={!chatEnabled} type="submit">Send</button>
                </div>
            </form>
        </div>
    );
};

export default Chatbox;